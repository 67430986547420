<template>
  <div id="clientInfo">
    <div id="panel-header">
      <span class="top-title">账号信息</span>
    </div>
    <div>
      <Alert v-show="alertShow" class="alert-info" type="warning" closable>
        {{ getAlert(account.type, account.expire_date) }}
      </Alert>
      <Card class="card-info" :style="getBgColor(account.type)">
        <div class="align-center">
          <Icon style="font-size: 140px" type="ios-ribbon"/>
          <h1>{{ getTypeName(account.type) }}</h1>
          <h5>{{ account.expire_date }} 到期</h5>
        </div>
      </Card>
      <div class="buy-button" v-show="buttonShow && is_top" >
<!--      <div class="buy-button"  >-->
        <Button type="warning" :style="getBgColor(account.type)+ ' border:0px;'" @click="upAccountOperate" >{{ getButton(account.type, account.expire_date) }}</Button>
      </div>

      <Row type="flex" justify="center" class="mt-20">
        <Col span="8" class="fs-26">
          <span>账号存储总量：<strong class="main-font-color">{{ account.usable_file_capacity }}</strong></span>
        </Col>
        <Col span="6" class="fs-26">
          <span>使用量：<strong class="main-font-color">{{ account.used_file_capacity }}</strong></span>
        </Col>
        <Col span="6" class="fs-26">
          <span>剩余量：<strong class="main-font-color">{{ account.left_file_capacity }}</strong></span>
        </Col>
      </Row>
    </div>

    <Modal v-model="pay.modal" title="付费操作" width="800" footer-hide>
      <div style="margin:0 auto;width:400px;">
        <Form ref="payFormItem" :label-width="100" label-colon label-position="left">
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="当前账户数">
                <strong class="main-font-color">{{ account.act_user_limit }}</strong>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="已用账户数">
                <strong class="main-font-color">{{ account.act_user_limit - left_user_number }}</strong>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="付费账户数">
                <Input v-model="pay.item.pay_accounts" placeholder="请填写付费账户数" @on-blur="setPayMoney"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="使用年限">
                <RadioGroup v-model="pay.item.system_time" @on-change="setPayMoney">
                  <Radio label="1">1年</Radio>
                  <Radio label="5" class="ml-20">5年</Radio>
                </RadioGroup>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="费用">
                <strong class="money-color">¥{{ pay.item.pay_money }}</strong>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="付款方式">
                <RadioGroup v-model="pay.item.pay_type" @on-change="setPayMoney" class="display-flex">
                  <Radio label="1" style="display: flex;align-items: center;">
                    <div style="height:36px;display: contents;">
                      <img style="width:80px;" src="https://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage/common/80_232_%E7%99%BD%E5%BA%95.png" alt="">
                    </div>
                  </Radio>
<!--                  <Radio label="2" style="display: flex;align-items: center;" class="ml-20">-->
<!--                    <div style="height:36px;display: contents;color:#333;font-size:18px;">-->
<!--                      <img style="height:36px;" src="https://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage/common/%E5%BE%AE%E4%BF%A1.png" alt="">-->
<!--                      <strong>微信</strong>-->
<!--                    </div>-->
<!--                  </Radio>-->
                </RadioGroup>
              </FormItem>
            </Col>
          </Row>

          <Row :gutter="20" class="mt-16">
            <Col span="24">
              <FormItem>
                <Button type="primary" :loading="pay.modal_loading" @click="goToPay">去支付</Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>

    <Modal v-model="payOperate.modal" title="支付操作" width="800" footer-hide>
      <div class="align-center">
        <div style="height:36px;" v-show="payOperate.item.pay_type == 1">
          <img style="width:80px;" src="https://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage/common/80_232_%E7%99%BD%E5%BA%95.png" alt="">
        </div>
        <div v-show="payOperate.item.pay_type == 2" class="wx-img">
          <img style="height:36px;" src="https://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage/common/%E5%BE%AE%E4%BF%A1.png" alt="">
          <strong>微信</strong>
        </div>
        <div class="mt-10">
          <canvas id="qrCode_header" style="width: 220px; height: 220px"></canvas>
        </div>
        <div class="mt-10 mb-6">
          <strong class="money-color fs-26">¥{{payOperate.item.pay_money}}</strong>
        </div>
      </div>
    </Modal>
  </div>

</template>

<script>
import QRCode from "qrcode";

import {getLeftUserNumber} from "@/api/setting/accountSetting";
import {apiCreatePayOrder, apiCheckPayOrderStatus, apiGetAccountInfo} from "@/api/setting/account";

export default {
  name: "Account",
  data() {
    return {
      is_top: false,

      account: {
        type: '',
        expire_date: '',
        left_file_capacity: '0B',
        used_file_capacity: '0B',
        usable_file_capacity: '0B',
        act_user_limit: 0,
      },

      left_user_number: 0,
      buttonShow: false,
      alertShow: false,
      currentBuyType: 1,
      payOperate: {
        modal: false,
        item: {
          pay_type: '',
          pay_money: '',
        },
        getTime:0,
      },
      pay: {
        modal: false,
        item: {
          pay_accounts: '',
          pay_money: 0,
          system_time: '1',
          pay_type: '1',
        }
      }
    }
  },
  methods: {
    goToPay() {
      this.pay.modal_loading = true;
      let param = {
        pay_accounts: this.pay.item.pay_accounts,
        pay_money: this.pay.item.pay_money,
        system_time: this.pay.item.system_time,
        pay_type: this.pay.item.pay_type,
        buy_type: this.currentBuyType,
      };
      apiCreatePayOrder(param).then(res => {
        if (res.data.err_no == 0) {

          this.payOperate.item.pay_type = this.pay.item.pay_type;
          this.payOperate.item.pay_money = this.pay.item.pay_money;

          if (this.pay.item.pay_type == 1) {//支付宝支付
            if (res.data.results.qr_code != undefined) {
              this.getQRCode(res.data.results.qr_code);

              //定时查询订单是否完成
              this.payOperate.getTime = 0;//循环次数设置为 0
              let timer = setInterval(() => {
                this.checkPayOrderStatus(timer, res.data.results.out_trade_no)
              }, 5000)
            }
          }

          this.payOperate.modal = true;
        } else {
          this.$Message.error(res.data.err_msg);
        }

        this.pay.modal = false;
        this.pay.modal_loading = false
      });
    },
    checkPayOrderStatus(timer, out_trade_no) {
      setTimeout(()=>{
        // 这里ajax 请求的代码片段和判断是否停止定时器
        apiCheckPayOrderStatus({'trade_no': out_trade_no}).then(res => {
          if (res.data.err_no == 0) {
            // 如需要停止定时器，只需加入以下：
            clearInterval(timer);
            this.$Message.success('支付成功！');
            this.payOperate.modal = false;
            this.getAccountInfo(false);
          } else {
            this.payOperate.getTime++;
            if (this.payOperate.getTime >= 300) {
              clearInterval(timer);
            }
          }
        })
      }, 0)
    },
    getQRCode(qrUrl) {
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.6, //二维码质量
        margin: 0, //二维码留白边距
        width: 280, //宽
        height: 280, //高
        text: "", //二维码内容
        color: {
          dark: "#343434", //前景色
          light: "#fff", //背景色
        },
      };

      let msg = document.getElementById("qrCode_header");
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, qrUrl, opts, (error) => {
        if (error) {
          this.$Message.error("二维码加载失败");
        }
      });
    },
    setPayMoney() {
      if (this.pay.item.pay_accounts % 1 !== 0) {
        this.$Message.error('付费账户数不能为小数');
        return;
      }

      if (this.pay.item.pay_accounts < (this.account.act_user_limit - this.left_user_number)) {
        this.$Message.error('付费账户数不能小于已使用用户数');
        this.pay.item.pay_accounts = this.account.act_user_limit - this.left_user_number;
      }


      if (parseInt(this.pay.item.system_time) === 1) {
        if (this.pay.item.pay_accounts == 1) {
          this.pay.item.pay_money = 100;
        }

        if (this.pay.item.pay_accounts <= 3 && this.pay.item.pay_accounts >= 2) {
          this.pay.item.pay_money = 258;
        }

        if (this.pay.item.pay_accounts >= 4) {
          this.pay.item.pay_money = 258 * (this.pay.item.pay_accounts - 2);
        }
      }

      if (this.currentBuyType == 1 && parseInt(this.pay.item.system_time) === 5) {
        if (this.pay.item.pay_accounts == 1) {
          this.pay.item.pay_money = 388;
        }

        if (this.pay.item.pay_accounts <= 3 && this.pay.item.pay_accounts >= 2) {
          this.pay.item.pay_money = 1000;
        }

        if (this.pay.item.pay_accounts >= 4) {
          this.pay.item.pay_money = 1000 * (this.pay.item.pay_accounts - 2);
        }
      }

      if (this.currentBuyType == 2 && parseInt(this.pay.item.system_time) == 5) {//续费
        if (this.pay.item.pay_accounts == 1) {
          this.pay.item.pay_money = 388;
        }

        if (this.pay.item.pay_accounts <= 3 && this.pay.item.pay_accounts >= 2) {
          this.pay.item.pay_money = 1000;
        }

        if (this.pay.item.pay_accounts >= 4) {
          this.pay.item.pay_money = 1000 * this.pay.item.pay_accounts;
        }
      }
    },
    getTypeName($type) {
      let $t = '';
      switch ($type) {
        case 0:
          $t = "试用会员";
          break;
        case 1:
          $t = "正式会员";
          break;
      }
      return $t;
    },
    upAccountOperate() {
      this.pay.item.pay_accounts = this.account.act_user_limit;

      this.setPayMoney();
      this.pay.modal = true;
    },
    getButton($type, expire_date) {
      let expire_time = new Date(expire_date).getTime();
      const timestamp = (new Date()).valueOf();

      if ($type == "1" && (expire_time - timestamp) <= 3600*1000*24*30) {
        this.buttonShow = true;
      } else {
        this.buttonShow = false;
      }

      if ($type == "0") {
        this.currentBuyType = 1;//购买
        return "立即购买";
      } else if ($type == "1") {
        this.currentBuyType = 2;//续费
        return "立即续费";
      }
    },
    getAlert($type, $expireDate) {
      let curTime = new Date().getTime();
      let expireDate = new Date($expireDate).getTime();

      if ($type == "0") {
        if (curTime >= expireDate) {//快过期和已过期
          this.alertShow = true;
          return "该账号是试用账号,已过期，请点击下方按钮，购买正式会员";
        }
      } else if ($type == "1") {
        if (curTime >= expireDate || (expireDate - curTime) / 1000 / 60 / 60 / 24 < 30) {//快过期和已过期
          this.alertShow = true;
          return "该账号是正式账号,快到过期时间";
        }
      }
    },
    getBgColor($type) {
      let $t = '';
      switch ($type) {
        case 0:
          $t = "background-color:#c27a39;";
          break;
        case 1:
          $t = "background-color:#09428c;";
          break;
      }
      return $t;
    },
    getAccountInfo(cache_flag = true) {
      apiGetAccountInfo({cache_flag: cache_flag}).then(res => {
        if (res.data.err_no == 0) {
          this.account.type = res.data.results.type;
          this.account.expire_date = res.data.results.expire_date;
          this.account.left_file_capacity = res.data.results.left_file_capacity;
          this.account.used_file_capacity = res.data.results.used_file_capacity;
          this.account.usable_file_capacity = res.data.results.usable_file_capacity;
          this.account.act_user_limit = res.data.results.act_user_limit;
        }
      });
    },
    getLeftUserNumber() {//获取剩余可用账号数
      getLeftUserNumber().then((res) => {
        this.left_user_number = res.data.results;
      });
    },
    setTopUser() {
      let user_info = JSON.parse(localStorage.getItem('user_info'));
      this.is_top = user_info.base_id != undefined && user_info.base_id==0 ? true : false;
    },
  },
  mounted() {
    this.setTopUser();

    this.getAccountInfo();
    this.getLeftUserNumber();


  },
}
</script>

<style scoped>
#clientInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.card-info {
  width: 640px;
  height: 320px;
  margin: 4% auto;
  font-size: 32px;
  color: #fff;
}

.alert-info {
  width: 640px;
  margin: 3% auto;
}

.wx-img {
  height:36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#333;
  font-size:18px;
}

.buy-button {
  width: 640px;
  margin: 0 auto;
  text-align: center;
}
</style>
