<template>
  <div class="layout">
    <Layout>
      <Layout>
        <Sider width="200">
          <SettingMenu @func="getMsgFormMenu" :active="title_seclectd"></SettingMenu>
        </Sider>
        <Content>
          <MyInfo v-if="parseInt(title_seclectd) === 1"></MyInfo>
          <LogList v-if="parseInt(title_seclectd) === 2"></LogList>
          <LoginLogList v-if="parseInt(title_seclectd) === 3"></LoginLogList>
          <Account v-if="parseInt(title_seclectd) === 4"></Account>
          <ExportLog v-if="parseInt(title_seclectd) === 5"></ExportLog>
          <Bill v-if="parseInt(title_seclectd) === 6"></Bill>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import SettingMenu from '@/components/menu/MeMenu.vue';
import MyInfo from '@/views/user/MyInfo.vue';
import LogList from '@/views/user/LogList.vue';
import LoginLogList from '@/views/user/LoginLogList.vue';
import Account from '@/views/user/Account.vue';
import ExportLog from '@/views/user/ExportLog';
import Bill from '@/views/user/Bill';

export default {
  name: "Me",
  components: {
    SettingMenu,
    MyInfo,
    LogList,
    LoginLogList,
    Account,
    ExportLog,
    Bill
  },
  data() {
    return {
      title_seclectd: "1",
      menuName: "Me",
    };
  },
  created() {
    this.title_seclectd = this.$route.query.selected !== undefined ? this.$route.query.selected : (localStorage.getItem(this.menuName) ? localStorage.getItem(this.menuName) : "1") ;
  },
  methods: {
    getMsgFormMenu(data) {
      this.title_seclectd = data;
      localStorage.setItem(this.menuName, data);
    }
  },
};
</script>

<style scoped>
.ivu-layout {
  background-color: transparent;
}

.ivu-layout-sider {
  background-color: transparent;
}

.ivu-menu {
  height: 100%;
}
</style>
