<template>
  <div id="clientInfo">
    <div id="panel-header">
      <span class="top-title">操作日志列表</span>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" ref="query" inline>
          <FormItem prop="operate_type">
            <Select @on-change="searchSubmit" v-model="formItem.operate_type" class="w-200" placeholder="请选择操作类型" filterable>
              <Option v-for="item in operateType" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker class="w-200" type="daterange" @on-change="timeChange" v-model="formItem.created_at" placement="bottom-end"
                        placeholder="请选择操作时间"></DatePicker>
          </FormItem>

          <FormItem>
            <Button type="text" class="main-font-color" @click="cancelSearch('query')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="log_list">
      <Table :columns="columnsInfo" :data="listInfo">
        <template slot-scope="{ row }" slot="operate_type">
          <span>{{ getTypeName(row.operate_type) }}</span>
        </template>
      </Table>
      <div class="page">
        <span class="fs12 disabled-color"><Icon type="ios-alert-outline"/> 默认展示最近半年有效操作数据，超过半年的数据可以通过查询方式获取</span>
        <Page :total="listTotal" :page-size="pageSize" show-total show-elevator @on-change="changePage"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogList",
  data() {
    return {
      formItem: {
        operate_type: '',
        created_at: '',
      },
      operateType: [
        {
          "id": 1,
          "name": '采购单操作'
        },
        {
          "id": 2,
          "name": '仓库库存操作'
        },
        {
          "id": 3,
          "name": '销售单操作'
        },
        {
          "id": 4,
          "name": '库存盘点操作'
        },
        {
          "id": 5,
          "name": '流水结算操作'
        },
        {
          "id": 6,
          "name": '生产操作'
        },
        {
          "id": 7,
          "name": '基础设置操作'
        },

      ],

      columnsInfo: [
        {
          title: '操作类型',
          key: 'operate_type',
          slot: 'operate_type',
          align: 'center',
        },
        {
          title: '描述',
          key: 'desc',
          align: 'center',
        },
        {
          title: '操作员',
          key: 'operator_name',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'created_at',
          align: 'center',
        },

      ],
      listInfo: [],
      search_flag: 0,
      listTotal: 0,
      pageSize: 0,
    };
  },
  methods: {

    getLogList($page = 1) {
      //获取货品列表
      let param = {
        params: {
          page: $page,
          operate_type: this.formItem.operate_type,
          created_at: this.formItem.created_at,
          search_flag: this.search_flag,
        }
      };

      this.$axios.get('/api/user/getLogList', param).then((response) => {
        if (response.data.err_no == 0) {
          this.listInfo = response.data.results.list;
          this.listTotal = response.data.results.total;
          this.pageSize = response.data.results.size;
        }
      });
    },

    getTypeName(typeId) {
      let typeName = '';
      let operateType = this.operateType;
      for (let item in operateType) {
        if (typeId == operateType[item].id) {
          typeName = operateType[item].name;
          break;
        }
      }
      return typeName;

    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.search_flag = 0;
      this.getLogList();
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.formItem.created_at = dateRange;
      } else {
        this.formItem.created_at = "";
      }

      this.searchSubmit();
    },
    searchSubmit() {
      this.search_flag = 1;
      this.getLogList();
    },
    changePage(page) {
      this.getLogList(page);
    },

  },
  mounted() {
    this.getLogList();
  },
};
</script>

<style scoped>
#clientInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#log_list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#log_list .page {
  margin-top: 16px;
}
</style>
<style>
#clientInfo .ivu-card {
  background-color: #fafafa;
}

#clientInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#clientInfo .ivu-form-item {
  margin-bottom: 0;
}

#log_list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#log_list .ivu-page {
  float: right;
}

#log_list .ivu-table-overflowX, #log_list .ivu-table-tip {
  overflow-x: hidden;
}

#log_list .ivu-table-wrapper {
  overflow: visible;
}
</style>





