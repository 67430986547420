import request from '@/plugins/http'

export function apiGetBillList(param) {
    return request.post('/api/account/getBillList', param);
}

export function apiCancelBillPayOperate(param) {
    return request.post('/api/account/cancelBillPayOperate', param);
}

export function apiDeleteBillPayOperate(param) {
    return request.post('/api/account/deleteBillPayOperate', param);
}



