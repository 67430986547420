<template>
  <div id="pay-desc" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">费用信息</span>
    </div>

    <div id="bill-list">
      <Table :columns="list.columns_info" :data="list.data">
        <template slot-scope="{ row, index }" slot="trade_no">
          <strong >{{row.trade_no}}</strong>
        </template>

        <template slot-scope="{ row, index }" slot="system_time">
          <span>{{row.buy_type == 1 ?'新购':'续费'}}</span><span> {{row.system_time}} 年</span>
        </template>

        <template slot-scope="{ row, index }" slot="pay_money">
          <strong class="money-color">{{row.pay_money}}</strong>
        </template>

        <template slot-scope="{ row, index }" slot="pay_type">
          <span v-show="row.pay_type == 1" class="fw-bold" style="color:#1E6FFF;">支付宝</span>
          <span v-show="row.pay_type == 2" class="fw-bold" style="color:#07C160;">微信</span>
        </template>

        <template slot-scope="{ row, index }" slot="pay_status">
          <Tag v-show="row.pay_status==0" color="error">待支付</Tag>
          <Tag v-show="row.pay_status==1" color="success">已支付</Tag>
          <Tag v-show="row.pay_status==2" color="default">已取消</Tag>
        </template>

        <template slot-scope="{ row, index }" slot="created_at">
          <span>{{ row.created_at.substring(0, 16) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="pay_time">
          <span>{{ row.pay_time.substring(0, 16) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="row.pay_status==0" type="text" class="fs-12 main-font-color" size="small" @click="goToPay(row)">
            <Icon type="logo-yen" />
            支付
          </Button>

          <Button v-show="row.pay_status==0" type="text" class="fs-12" size="small" @click="cancelPayOperate(row.id)">
            <Icon type="ios-close-circle" />
            取消
          </Button>

          <Button v-show="row.pay_status==2" type="text" class="fs-12" size="small" @click="deletePayOperate(row.id)">
            <Icon type="ios-trash-outline" />
            删除
          </Button>

          <span v-show="row.pay_status==1" class="color-grey">------</span>
        </template>
      </Table>
      <div class="page">
        <span class="fs12 disabled-color"><Icon type="ios-alert-outline"/>未支付订单有效期为一个小时，超时后系统将自动取消未支付订单！</span>
        <Page :total="list.total" :page-size="list.page_size" show-total show-elevator @on-change="changePage"/>
      </div>
    </div>

    <Modal v-model="payOperate.modal" title="支付操作" width="800" footer-hide>
      <div class="align-center">
        <div style="height:36px;" v-show="payOperate.item.pay_type == 1">
          <img style="width:80px;" src="https://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage/common/80_232_%E7%99%BD%E5%BA%95.png" alt="">
        </div>
        <div v-show="payOperate.item.pay_type == 2" style="height:36px;display: flex;align-items: center;justify-content: center;color:#333;font-size:18px;">
          <img style="height:36px;" src="https://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage/common/%E5%BE%AE%E4%BF%A1.png" alt="">
          <strong>微信</strong>
        </div>
        <div class="mt-10">
          <canvas id="qrCode_header" style="width: 220px; height: 220px"></canvas>
        </div>
        <div class="mt-10 mb-6">
          <strong class="money-color fs-26">¥{{payOperate.item.pay_money}}</strong>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import QRCode from "qrcode";

import {apiGetBillList, apiCancelBillPayOperate, apiDeleteBillPayOperate} from "@/api/user/bill";
import {apiCheckPayOrderStatus} from "@/api/setting/account";

export default {
  name: "Bill",
  data() {
    return {
      payOperate: {
        modal: false,
        item: {
          pay_type: '',
          pay_money: '',
        }
      },
      list: {
        data:[],
        columns_info: [
          {
            title: '交易号',
            key: 'trade_no',
            slot: 'trade_no',
            width: 190,
            align: 'center',
          },
          {
            title: '账号数量',
            key: 'pay_accounts',
            align: 'center',
          },
          {
            title: '金额',
            key: 'pay_money',
            slot: 'pay_money',
            align: 'center',
          },
          {
            title: '使用期限',
            key: 'system_time',
            slot: 'system_time',
            align: 'center',
          },
          {
            title: '付费方式',
            key: 'pay_type',
            slot: 'pay_type',
            align: 'center',
          },
          {
            title: '付费状态',
            key: 'pay_status',
            slot: 'pay_status',
            align: 'center',
          },
          {
            title: '付费时间',
            key: 'pay_time',
            slot: 'pay_time',
            width: 150,
            align: 'center',
          },
          {
            title: '创建时间',
            key: 'created_at',
            slot: 'created_at',
            width: 150,
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            width: 150,
            align: 'center',
            slot: 'action',
          }
        ],
        page: 1,
      }
    }
  },
  methods: {
    goToPay(item) {
      this.payOperate.item.pay_type = item.pay_type;
      this.payOperate.item.pay_money = item.pay_money;
      if (item.pay_response.qr_code != undefined) {
        this.getQRCode(item.pay_response.qr_code);

        //定时查询订单是否完成
        this.payOperate.getTime = 0;//循环次数设置为 0
        let timer = setInterval(() => {
          this.checkPayOrderStatus(timer, item.pay_response.out_trade_no)
        }, 5000)
      }

      this.payOperate.modal = true;
    },
    checkPayOrderStatus(timer, out_trade_no) {
      setTimeout(()=>{
        // 这里ajax 请求的代码片段和判断是否停止定时器
        apiCheckPayOrderStatus({'trade_no': out_trade_no}).then(res => {
          if (res.data.err_no == 0) {
            // 如需要停止定时器，只需加入以下：
            clearInterval(timer);
            this.$Message.success('支付成功！');
            this.payOperate.modal = false;

            this.getOrderList();
          } else {
            this.payOperate.getTime++;
            if (this.payOperate.getTime >= 300) {
              clearInterval(timer);
            }
          }
        })
      }, 0)
    },
    getQRCode(qrUrl) {
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.6, //二维码质量
        margin: 0, //二维码留白边距
        width: 220, //宽
        height: 220, //高
        text: "", //二维码内容
        color: {
          dark: "#343434", //前景色
          light: "#fff", //背景色
        },
      };

      let msg = document.getElementById("qrCode_header");
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, qrUrl, opts, (error) => {
        if (error) {
          this.$Message.error("二维码加载失败");
        }
      });
    },
    deletePayOperate(id) {
      this.$Modal.confirm({
        title: "删除支付费用信息",
        content: '确定删除支付费用信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          apiDeleteBillPayOperate({id:id}).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getOrderList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          })
        },
        onCancel: () => {
        }
      });

    },
    cancelPayOperate(id) {
      this.$Modal.confirm({
        title: "取消支付费用信息",
        content: '确定取消支付费用信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          apiCancelBillPayOperate({id:id}).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getOrderList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          })
        },
        onCancel: () => {
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getPayDescList();
    },
    getOrderList() {
      let param = {
        page: this.list.page,
      }
      apiGetBillList(param).then(res => {
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].pay_money = parseFloat(res.data.results.list[i].pay_money);
            res.data.results.list[i].pay_status = parseFloat(res.data.results.list[i].pay_status);
          }
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.page_size = res.data.results.size;
        }
      })
    }
  },
  mounted() {
    this.getOrderList()
  },
}
</script>

<style scoped>

#bill-list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#bill-list .page {
  margin-top: 16px;
}
</style>
<style>

#pay-desc .ivu-form-item {
  margin-bottom: 0;
}

#bill-list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#bill-list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#bill-list .ivu-page {
  float: right;
}

#bill-list .ivu-table-wrapper {
  min-height: auto;
}

#bill-list .ivu-table-fixed-body {
  min-height: auto;
}

#bill-list .ivu-table-overflowX, #bill-list .ivu-table-tip {
  overflow-x: hidden;
}

#bill-list .ivu-tag {
  border: 0;
}
</style>
