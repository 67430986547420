<template>
  <div id="export-log" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">导出日志</span>
    </div>

    <div id="filter-bar" class="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline>
          <FormItem prop="created_at">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                        placeholder="请选择导出日期" v-model="list.search_item.created_at" class="w-200"></DatePicker>
          </FormItem>

          <FormItem>
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list" class="main-list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="type">
          <span>{{ getTypeText(row.type) }}</span>
        </template>

        <template slot-scope="{ row }" slot="created_at">
          <span>{{ row.created_at.substring(0, 16) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
            <a :href="row.download_url" v-if="row.status == 2">
              <Button type="text" size="small" class="main-font-color fs-12">
                <Icon type="ios-download"/>
                下载
              </Button>
            </a>

            <a target="_blank" :href="'/DataView?transfer_id='+row.transfer_id" v-if="row.status == 2 && row.transfer_id">
              <Button type="text" size="small" class="info-font-color fs-12">
                <Icon type="ios-search"/>
                查看
              </Button>
            </a>
        </template>
      </Table>
      <div class="page mt-16">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getExportLogList
} from '@/api/user/exportLog';

export default {
  name: "ExportLog",
  data() {
    return {
      list: {
        search_item: {
          created_at: []
        },
        columns: [
          {
            title: '导出标题',
            key: 'title',
          },
          {
            title: '导出状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '导出类别',
            key: 'type',
            slot: 'type',
            align: 'center',
          },
          {
            title: '导出日期',
            key: 'created_at',
            slot: 'created_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
          }
        ],
        data: [],
        size: 10,
        page: 1,
      }
    }
  },
  methods: {
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.list.search_item.created_at = dateRange;
      } else {
        this.list.search_item.created_at = "";
      }

      this.searchSubmit();
    },
    getStatusColor(index) {
      let name = '';
      switch (index) {
        case 0:
          name = 'color:#ed4014';
          break;
        case 1:
          name = 'color:#2775ff';
          break;
        case 2:
          name = 'color:#19be6b';
          break;
        case 3:
          name = 'color:#ff9900';
          break;
      }
      return name;
    },
    getTypeText(index) {
      let rs = '';
      switch (index) {
        case 'EXPORT_SALE_LIST':
          rs = '销售';
          break;
        case 'EXPORT_GOODS_LIST':
          rs = '货品';
          break;
      }
      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 0:
          rs = '未执行';
          break;
        case 1:
          rs = '执行中';
          break;
        case 2:
          rs = '执行成功';
          break;
        case 3:
          rs = '执行失败';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    getMainList() {
      let param = {
        page: this.list.page,
        page_size: this.list.size,
        main_no: this.list.search_item.main_no,
        sale_no: this.list.search_item.sale_no,
        out_no: this.list.search_item.out_no,
        created_at: this.list.search_item.created_at,
      };

      getExportLogList(param).then((res) => {
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      this.getMainList();
    },
  },
  mounted() {
    this.getMainList();
  },
}
</script>

<style scoped>

</style>
<style>
#export-log .ivu-card {
  background-color: #fafafa;
}

#export-log .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#export-log .ivu-form-item {
  margin-bottom: 0;
}

.main-list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.main-list .ivu-table-wrapper {
  overflow: visible;
}

#export-log .ivu-select-dropdown {
  left: 0 !important;
}
</style>
