import request from '@/plugins/http'

export function apiCreatePayOrder(param) {
    return request.post('/api/account/createPayOrder', param);
}

export function apiCheckPayOrderStatus(param) {
    return request.post('/api/account/checkBillPayOrderStatus', param);
}

export function apiGetAccountInfo(param) {
    return request.post('/api/account/info', param);
}

